import styled from 'styled-components';
import { Button, Container, Navbar } from 'react-bootstrap';
import colors from '../../styleguide/colors';

const breakpoints = {
  desktop: '(min-width: 1025px)',
  tablet: '(max-width: 1024px)',
  plus: '(max-width: 800px)',
  phone: '(max-width: 767px)',
};

export const BlueLine = styled.div`
  background-color: ${colors.nlBlue};
  height: 15px;
`;

export const Wrapper = styled.div`
  @media (min-width: 1000px) {
    margin: 0 auto;
  }
`;

export const HeaderButton = styled(Button)`
  background-color: ${colors.nlBlue};
  border-color: ${colors.nlBlue};
  border-width: 3px;
  width: 220px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${colors.white};
  font-weight: 450;
  font-size: 16px;
  :focus {
    outline: 0 none;
    box-shadow: none;
    background-color: ${colors.nlBlue};
    border-color: ${colors.nlBlue};
  }

  @media ${breakpoints.tablet} {
    width: auto;
    font-size: 13px;
  }
  @media ${breakpoints.phone} {
    width: auto;
    font-size: 9px;
  }
`;

export const HeaderContainer = styled(Container)`
`;

export const Header = styled(Navbar)`
  background-color: ${colors.nlBlue};
  padding-top: 8px;
  padding-bottom: 8px;
  @media ${breakpoints.tablet} {
    padding-top: 4px;
    padding-bottom 4px;
  }
`;

export const StyledSubText = styled(Container)`
  margin-top: 15px;
  margin-left: 360px;
  

  @media ${breakpoints.tablet} {
    margin-left: 224px;
  }
  @media ${breakpoints.phone} {
    margin-left: 150px;
  }
`;

export const SubText = styled.p`
  font-weight: 600 !important;
  font-size: 18px !important;
  @media ${breakpoints.tablet} {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  @media ${breakpoints.phone} {
    font-weight: 600 !important;
    font-size: 12px !important;
  }
`;

export const BottomSubText = styled(Container)`
  margin-top: 15px;
  margin-left: 225px;
  

  @media ${breakpoints.tablet} {
    margin-left: 120px;
  }
  @media ${breakpoints.phone} {
    margin-left: 90px;
  }
`;

export const BottomSection = styled(Container)`
  overflow: hidden;
  background-color: ${colors.white};
  height: 460px;
  justify-content: center;
  @media ${breakpoints.phone} {
    height: 360px;
  }
`;

export const BottomSectionText = styled(Container)`
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  padding-top: 125px;
  padding-bottom 75px;
  width: 900px;
  @media ${breakpoints.tablet} {
    width: 600px;
    font-weight: 600;
    font-size: 25px;
  }
  @media ${breakpoints.phone} {
    padding-top: 60px;
    width: 300px;
    font-weight: 600;
    font-size: 20px;
  }
`;

