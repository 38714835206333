import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from './routes.js';
import './App.css';
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Home from './scenes/Home/Home';
import Privacy from "./scenes/Static/Privacy";
import Terms from "./scenes/Static/Terms";
import Blog from "./scenes/Blog";
import Blog1 from "./scenes/Blog/blog1.js";
import AppDownload from './components/AppDownload/AppDownload.js';

function App() {
  return (
    <Router>
        <Switch>
          <Route exact path='/app' component={AppDownload} />
      <div className="App">
        <Header />
          <Route exact path={routes.privacy} component={Privacy} />
          <Route eaxct path={routes.terms} component={Terms} />
          <Route exact path={routes.blog} component={Blog} />
          <Route exact path={routes.blog1} component={Blog1} />
          <Route exact path={routes.home} component={Home} />
        <Footer />
      </div>
        </Switch>
    </Router>
  );
}

export default App;
