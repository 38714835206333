import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Title = styled.div`
  color: #3A3A3A;	
  font-family: Lato;	
  font-size: 36px;	
  font-weight: 300;	
  letter-spacing: -1.38px;	
  line-height: 48px;
`;

export const BlogTitle = styled.div`
  color: #3A3A3A;	
  font-family: Lato;	
  font-size: 28px;	
  font-weight: 300;	

  ${breakpoint('lg')`
    font-size: 40px;
  `}
`;

export const Card = styled.div`
  height: 350px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  text-align: center;
`;

export const SubHeader = styled.div`
  font-size: 16px;
  font-family: Lato;	
  text-align: center;
  margin-bottom: 20px;

  ${breakpoint('lg')`
    font-size: 21px;
  `}
`;

export const SubSubHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #6f7c72;	
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const Paragraph = styled.div`
  color: #939d95;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
`
export const Bullet = styled.div`
  color: #939d95;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  paddng-left: 15px;
  font-size: 18px;
`