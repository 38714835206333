import React from 'react';
import styled from 'styled-components';
import styles from './styles.module.css'
import {
    Navbar,
    Nav,
    NavItem
} from 'react-bootstrap';
import logo from '../../assets/img/niceleads-logo.png';

const breakpoints = {
    desktop: '(min-width: 1025px)',
    tablet: '(max-width: 1024px)',
    plus: '(max-width: 800px)',
    phone: '(max-width: 767px)',
};

function Header() {
    return (
        <Navbar expand="sm" style={{ overflow: 'hidden' }}>
            <Navbar.Brand href="/" style={{ marginLeft: '15%' }}>
                <Image src={logo} alt="NiceLeads" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav" className="justify-content-end" style={{ marginRight: '5vw' }}>
                <Nav>
                    <Nav.Link href='/' className={styles.item}>
                        <NavItem className={styles.itemText}>Home</NavItem>
                    </Nav.Link>
                    <Nav.Link href='/blog' className={styles.item}>
                        <NavItem className={styles.itemText}>Blog</NavItem>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export const Image = styled.img`
  margin: auto;
  width: auto;
  @media ${breakpoints.phone} {
    width: 100px;
  }
`;

export default Header;