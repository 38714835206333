import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';;


class Privacy extends React.Component {

  render() {
    return (
      <div>
        <div style={{ width: '100%', height: 10, backgroundColor: 'rgba(216,216,216,0.42)' }}></div>
        <Container style={{ paddingTop: 100, paddingBottom: 100, maxWidth: 'none' }}>
          <Row>
            <Col />
            <Col xs={10}>
              <p>NiceLeads Privacy Policy</p>
              <p>Last Updated: August 9, 2021</p>
              <div style={{ textAlign: 'left' }}>
                <p>NiceLeads’ mission is to help professionals discover the value of their relationships every day.</p>
                <p>In order to achieve that mission, information security and our customers’ privacy are values on which we do not compromise. NiceLeads was built on the idea that our relationships are the most valuable asset we have. We want to protect them, and be their gatekeeper, but also maximize their value. Our unique service matches two members based on their preferences, thus providing the opportunity to connect professionals with others they’ll want to network with.</p>
                <p><b>Scope of this Privacy Policy</b></p>
                <p>NiceLeads (“NiceLeads,” “we,” “our,” and/or “us”) values the privacy of individuals who use our application, websites, and related services (collectively, the “NiceLeads Service”). This privacy policy (the “Privacy Policy”) explains how we collect, use, and share information from NiceLeads users (“Users”). Beyond the Privacy Policy, your use of NiceLeads is also subject to our Terms of Service (<a href="terms">niceleads.com/terms</a>).</p>
                <p><b>Information We Collect</b></p>
                <p><b>A. Information You Provide to Us</b></p>
                <p><b>Registration Information.</b> When you sign up for a NiceLeads account, you give us your name, optional picture, and email address. You can also add a tagline describing yourself and what industry or industries you are a part of.</p>
                <p><b>User Profile Information.</b> When you join the NiceLeads community, you create a NiceLeads Profile and a Wish List to network with other members who can help you with important introductions. Completing your Profile and Wish List are optional, and you can share as little or as much about yourself as you want. Before you reach out to another member, or they reach out to you, and before you accept a Networking Request, only your first name and last initial are shared along with your tagline and the city and state where you live. Full names are not revealed until you send a request or you can see the other member when they accept your request. Only after a networking request is received, or accepted, can a user see mutual contacts. Only mutual contacts are visible to the other user, not your entire address book, and then only the first and last name of the mutual contact, as each user stores them in their device, is displayed. You also have a Private Profile section in your account, which is shared with the other User you are communicating with when you initiate or accept a Networking Request. This ensures you are a real person and allows them to ask mutual friends about your trustworthiness. Once a networking request has been accepted, both users may initiate a chat within the app.</p>
                <p><b>Payment Method.</b> The application is free for now and there is no payment mechanism.</p>
                <p><b>Communications.</b> If you contact us directly, we may receive additional information about you. For example, when you contact our Customer Support Team, we will receive your name, email address, possibly your phone number, the contents of a message or attachments that you may send to us, and other information you choose to provide.</p>
                <p><b>B. Information We Collect When You Use the NiceLeads Service</b></p>
                <p><b>Location Information.</b> NiceLeads is all about connecting Users who can help one another network. To do this, we may need to know where you are, in which case when you open NiceLeads on your mobile device, we will know your location. We may also collect the precise location of your device whenever the app is running in the foreground or background.</p>
                <p><b>Device Information.</b> NiceLeads receives information from Users’ devices, including IP address, web browser type, mobile operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notification tokens. We also may collect mobile sensor data from Users’ devices (such as speed, direction, height, acceleration or deceleration) to improve location accuracy and analyze use patterns.</p>
                <p><b>Use Information.</b> To help us understand how you use the NiceLeads Service and to help us improve it, we automatically receive information about your interactions with the NiceLeads Service such as the pages or other content you view, your actions within the NiceLeads app, and the dates and times of your visits.</p>
                <p><b>Call and Text Information.</b> We may work with a third-party partner to facilitate messages between Users. Among the information we may receive about these communications is the date and time of the chat and the content of any message.</p>
                <p><b>Address Book Contacts.</b> If you permit NiceLeads to access the address book on your device through the permission system used by your mobile Service, we may access and store names and contact information from your address book: This allows us to facilitate invitations and social interactions that you initiate through our Service and for other purposes described in this privacy policy or at the time of consent or collection. You may request to delete your account at any time and we do not reach out to your contacts unless you initiate it, or we are compelled by a court order.</p>
                <p><b>Information from Cookies and Similar Technologies.</b> We collect information through the use of “cookies,” tracking pixels, and similar technologies to understand how you navigate through the NiceLeads Service and interact with NiceLeads, to learn what content is popular, and to save your preferences. Cookies are small text files that web servers place on your device to store basic information and to help websites and apps recognize your browser. We may use both session cookies and persistent cookies: A session cookie disappears after you close your browser; A persistent cookie remains after you close your browser and may be accessed every time you use the NiceLeads Service. You should consult your web browser(s) to modify your cookie settings. Please note that if you delete or choose not to accept cookies from us, you may be missing out on certain features of the NiceLeads Service.</p>
                <p><b>C. Information We Collect from Third Parties</b></p>
                <p><b>Third Party Partners.</b> We may receive additional information about you, such as demographic data, payment information, or fraud detection information, from third party partners and combine it with other information that we have about you.</p>
                <p><b>How We Use the Information We Collect</b></p>
                <p>
                  We use the information we collect from all Users to:
                  <ul>
                    <li>Match Users with one another via Wish Lists;</li>
                    <li>Provide, improve, expand, and promote the NiceLeads Service;</li>
                    <li>Analyze how the NiceLeads community uses the NiceLeads Service;</li>
                    <li>Communicate with you, either directly or through one of our partners, including for marketing and promotional purposes;</li>
                    <li>Personalize the NiceLeads experience for you;</li>
                    <li>Send you push notifications;</li>
                    <li>Facilitate transactions and payments;</li>
                    <li>Provide you with customer support;</li>
                    <li>Find and prevent fraud.</li>
                  </ul>
                </p>
                <p><b>How We Share the Information We Collect</b></p>
                <p><b>A. Sharing Between Users</b></p>
                <p><b>Sharing between Users.</b> Users who have been matched via their Wish Lists are able to see basic information about each other, such as names, photo, city and state of where they’re from and / or live, and any information they have added to their Profiles. NiceLeads may offer Users the ability to invite contacts to use the NiceLeads Service (such contacts, “Invitees,” and such messages to contacts, “Invitations”). If an Invitee does not respond to a Networking Request, NiceLeads may send periodic reminder messages to such User.</p>
                <p>
                  <b>Other Sharing.</b> We may share your information with third parties in the following cases:
                  <ul>
                    <li>While negotiating or in relation to a change of corporate control such as a restructuring, merger or sale of our assets;</li>
                    <li>If a government authority requests information and we think disclosure is required or appropriate in order to comply with laws, regulations, or a legal process;</li>
                    <li>With law enforcement officials, government authorities, or third parties if we think doing so is necessary to protect the rights, property, or safety of the NiceLeads community;</li>
                    <li>To provide information about the use of the NiceLeads Service to potential business partners in aggregated or de-identified form that can’t reasonably be used to identify you; and</li>
                    <li>Whenever you consent to the sharing.</li>
                  </ul>
                </p>
                <p><b>Your Choices</b></p>
                <p><b>Email Subscriptions.</b> You can always unsubscribe from our commercial or promotional emails but we will still send you transactional and relational emails about your use of the NiceLeads Service.</p>
                <p><b>Push Notifications.</b> You can opt out of receiving push notifications through your device settings. Please note that opting out of receiving push notifications may impact your use of the NiceLeads Service (such as receiving a notification to network with someone who can help you with an introduction.</p>
                <p><b>Location Information.</b> While you can prevent your device from sharing location information at any time through your Device’s operating system settings, working with people in your area can help you achieve your professional goals and without it we can’t provide an optimal user experience.</p>
                <p><b>Editing and Accessing Your Information.</b> You can review and edit certain account information by logging in to your account settings and profile. If you would like to terminate your NiceLeads account, please contact us through info@niceleads.com with your request. If you choose to terminate your account, we will deactivate it for you but may retain information from your account for a certain period of time and disclose it in a manner consistent with our practices under this Privacy Policy for accounts that are not closed. We also may retain information from your account to collect any fees owed, resolve disputes, troubleshoot problems, analyze usage of the NiceLeads Service, assist with any investigations, prevent fraud, enforce our Terms of Service, or take other actions as required or permitted by law.</p>
                <p><b>Other</b></p>
                <p><b>Data Security.</b> We are committed to protecting the data of the NiceLeads community. Even though we take reasonable precautions to protect your data, no security measures can be 100% secure, and we cannot guarantee the security of your data.</p>
                <p><b>Children’s Privacy.</b> NiceLeads is not directed to children, and we don’t knowingly collect personal information from children under 13. If we find out that a child under 13 has given us personal information, we will take steps to delete that information. If you believe that a child under the age of 13 has given us personal information, please contact us at info@niceleads.com.</p>
                <p><b>Changes to Our Privacy Policy.</b> We may make changes to this Privacy Policy from time to time. If we make any material changes, we will let you know through the NiceLeads Service, by email, or other communication. We encourage you to read this Privacy Policy periodically to stay up-to-date about our privacy practices. As long as you use the NiceLeads Service, you are agreeing to this Privacy Policy and any updates we make to it.</p>
                <p><b>Contact Information.</b> Feel free to contact us at any time with any questions or comments about this Privacy Policy, your personal information, our use and sharing practices, or your consent choices by contacting info@niceleads.com.</p>
              </div>
            </Col>
            <Col />
          </Row>
        </Container>
      </div>
    );
  }
};

export default Privacy;
