import styled from 'styled-components';
import { InputGroup, Button, Form } from 'react-bootstrap';
import colors from "../../styleguide/colors";

const breakpoints = {
  desktop: '(min-width: 1025px)',
  tablet: '(max-width: 1024px)',
  plus: '(max-width: 800px)',
  phone: '(max-width: 767px)',
};


export const StyledEmailInput = styled(InputGroup)`
  line-height: normal;
  display: inline-flex;
  width: 600px;

  @media ${breakpoints.tablet} {
    font-size: 16px;
    width: 400px;
  }
  @media ${breakpoints.phone} {
    font-size: 13px;
    width: 300px;
  }
`;

export const StyledButton = styled(Button)`
  background: ${colors.nlBlue};
  border-color: ${colors.nlBlue};
  border-radius: 60px;
  font-size: 19px;
  font-weight: 900;
  width: 300px;

  @media ${breakpoints.tablet} {
    font-size: 13px;
    width: 200px;
    height: 35px;
  }
  @media ${breakpoints.phone} {
    font-weight: 600;
    font-size: 11px;
    width: 150px;
    height: 25px;
  }
`;

export const StyledInput = styled(Form.Control)`
  border-radius: 60px;
  display: inline-flex;
  width: 300px;
  height: 48px;
  font-size: 18.9px;

  @media ${breakpoints.tablet} {
    font-size: 13px;
    width: 200px;
    height: 35px;
  }
  
  @media ${breakpoints.phone} {
      font-size: 12px;
      width: 150px;
      height: 25px;
  }
`;
