import styled from 'styled-components';
import breakpoint from "styled-components-breakpoint";
import { Container, Row } from "react-bootstrap";
import colors from "../../styleguide/colors";

const breakpoints = {
  desktop: '(min-width: 1025px)',
  tablet: '(max-width: 1024px)',
  plus: '(max-width: 800px)',
  phone: '(max-width: 767px)',
};


export const Wrapper = styled.div`
  overflow:hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
`;

export const HeroOverlay = styled(Container)`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5vw 0 5vw 0;
  width: 70%;
  justify-content: center;
  align-items: flex-start;
  margin-top: 100px;
  @media ${breakpoints.tablet} {
    margin-top: 50px;
  }
  @media ${breakpoints.phone} {
    width: 90%;
  }
  
  h1 {
    text-align: left;
    font-weight: 800;
    color: ${colors.white};
    margin-bottom: 10px;
    font-size: 3vw;
    @media ${breakpoints.phone} {
      font-size: 26px;
    }
  }
  
  p {
    text-align: left;
    font-weight: 500;
    color: ${colors.white};
    font-size: 2vw;
    margin-bottom: 1.5rem;
    @media ${breakpoints.tablet} {
      margin-bottom: 1rem;
    }
    @media ${breakpoints.phone} {
      font-size: 12px;
    }
  }
  .headerAppDownText{
    margin-bottom: 1rem;
    @media ${breakpoints.tablet} {
      margin-bottom: 0.6rem;
    }
    @media ${breakpoints.phone} {
      font-size: 10px;
    }
  }

`;

export const Hero = styled.img`
  width: 100%;
  visibility: hidden;
  @media ${breakpoints.phone} {
    height: 350px;
    width: auto;
  }
`;
