import React from 'react';
import {
    Row,
    Col
} from 'react-bootstrap';
import {
    Wrapper,
    Section,
    StyledCol,
    ImageCol,
    Image,
    StyledTitle,
    StyledBullet
} from './phoneSectionStyle';
import DesktopBreakpoint from '../ResponsiveUtilities/desktop_breakpoint';
import TabletBreakpoint from '../ResponsiveUtilities/tablet_breakpoint';
import PhoneBreakpoint from '../ResponsiveUtilities/phone_breakpoint';


const PhoneSection = ({ style, image, title, rows, bgColor, addSpace = false }) => {
    return (
        <Wrapper style={{ ...style, backgroundColor: `${bgColor}` }}>
            <DesktopBreakpoint>
                <Section>
                    <ImageCol>
                        {addSpace ?
                            <Image src={image} style={{ marginBottom: '100px' }} /> :
                            <Image src={image} />
                        }
                    </ImageCol>
                    <StyledCol>
                        <Row>
                            <StyledTitle>{title}</StyledTitle>
                        </Row>
                        <Row>
                            {rows.map(row => {
                                return (
                                    <StyledBullet>{row}</StyledBullet>
                                )
                            }
                            )}
                        </Row>
                    </StyledCol>
                </Section>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <Section>
                    <StyledCol>
                        <Row>
                            <StyledTitle>{title}</StyledTitle>
                        </Row>
                        <Row>
                            {rows.map(row => {
                                return (
                                    <StyledBullet>{row}</StyledBullet>
                                )
                            }
                            )}
                        </Row>
                    </StyledCol>
                    <ImageCol>
                        {addSpace ?
                            <Image src={image} style={{ marginBottom: '100px' }} /> :
                            <Image src={image} />
                        }
                    </ImageCol>
                </Section>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Section>
                    <StyledCol>
                        <Row>
                            <StyledTitle>{title}</StyledTitle>
                        </Row>
                        <Row>
                            {rows.map(row => {
                                return (
                                    <StyledBullet>{row}</StyledBullet>
                                )
                            }
                            )}
                        </Row>
                    </StyledCol>
                    <ImageCol>
                        {addSpace ?
                            <Image src={image} style={{ marginBottom: '100px' }} /> :
                            <Image src={image} />
                        }
                    </ImageCol>
                </Section>

            </PhoneBreakpoint>

        </Wrapper>
    );
};

export default PhoneSection;
