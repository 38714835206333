import React, { useState, useEffect } from 'react';
import {
    InputGroup,
    Form
} from 'react-bootstrap';
import {
    StyledEmailInput,
    StyledButton,
    StyledInput
} from './emailFieldStyle';

function EmailField() {
    const [isSubmitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("")

    useEffect(() => {
        if (isSubmitted) {
            submitEmail(email).then(() => {
                setSubmitted(false);
            });
        }
    }, [isSubmitted]);

    const handleClick = () => setSubmitted(true);

    return (
        <Form>
            <StyledEmailInput>
                <StyledInput
                    type="email"
                    placeholder="email@domain.com"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputGroup.Append>
                    <StyledButton
                        variant="primary"
                        disabled={isSubmitted}
                        onClick={!isSubmitted ? handleClick : null}
                    >
                        {isSubmitted ? 'Submitted!' : 'Join Our Wait List'}
                    </StyledButton>
                </InputGroup.Append>
            </StyledEmailInput>
        </Form>
    );
}

// Email Handling

var mandrill = require('mandrill-api/mandrill');
var mandrill_client = new mandrill.Mandrill('i2I4KqogpLBLYr2GAhkVdg');

function submitEmail(email) {

    var message = {
        "html": "<p>The following email has just signed up for NiceLeads Marketing: " + email + " .</p>",
        "subject": "New Sign Up for NiceLeads Wish List!",
        "from_email": "HeadPro@findagame.io",
        "from_name": "NiceLeads Marketing",
        "to": [{
            "email": "lblaylock@niceleads.com",
            "name": "Lee",
            "type": "to"
        }],
    };
    var async = false;
    var ip_pool = "Main Pool";
    var send_at = "2019-10-10 00:00:00";
    mandrill_client.messages.send({ "message": message, "async": async, "ip_pool": ip_pool, "send_at": send_at }, function (result) { }, function (e) {
        // Mandrill returns the error as an object with name and message keys
        console.log('A mandrill error occurred: ' + e.name + ' - ' + e.message);
        // A mandrill error occurred: Unknown_Subaccount - No subaccount exists with the id 'customer-123'
    });


    return new Promise((resolve) => setTimeout(resolve, 4000));
}



export default EmailField;