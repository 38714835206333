import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { BlogTitle, SubHeader } from './style';
import styles from './styles.module.css'
import blog1 from './blog1.png';
import routes from '../../routes'


const Tile = ({ title, text, route, img }) => {
  return (
    <Col xs={12} md={4}>
      <a href={route} style={{ textDecoration: 'none' }}>
        <Card className={styles.tileCard}>
          <Card.Img style={{ padding: 15, paddingBottom: 0 }} variant="top" src={img} />
          <Card.Body>
            <Card.Title style={{ textAlign: 'center', fontWeight: 'bold', color: '#7b877f', fontSize: '1.1rem' }}>{title}</Card.Title>
            <Card.Text style={{ textAlign: 'center', color: '#7b877f' }}>
              {text}
            </Card.Text>
          </Card.Body>
        </Card>
      </a>
    </Col>
  )
}

class Blog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div style={{ width: '100%', height: 10, backgroundColor: 'rgba(216,216,216,0.42)' }}></div>
        <div className={styles.blogContainer} >
          <Row style={{ textAlign: 'center' }}>
            <Col xs={12}>
              <BlogTitle style={{ marginBottom: 35, marginTop: 35, marginLeft: '10%', marginRight: '10%' }}>
                Taking The Work Out Of Networking
              </BlogTitle>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 10 }}>
            <Col />
            <Col xs={10}>
              <SubHeader>Enterprise business networking made easy</SubHeader>
            </Col>
            <Col />
          </Row>
          {/* <Row style={{ paddingBottom: 30 }}>
            <Col />
            <Col xs={10}>
              <SubHeader style={{ fontStyle: 'italic' }}>By <a href="team/james">James A. Frank</a>, Chief Content Officer, Find A Game</SubHeader>
            </Col>
            <Col />
          </Row> */}
          <Row className={styles.tileRow}>
            <Tile title="The NiceLeads Story"
              text="Success in business comes down to introductions, relationships, and trust. CEO Lee Blaylock explains how those are the building blocks of NiceLeads."
              route={routes.blog1}
              img={blog1} />
          </Row>
        </div>
      </div>
    );
  }
};

export default Blog;
