import React from 'react';
import styled from 'styled-components';
import {
    Row,
    Col
} from 'react-bootstrap';
import DesktopBreakpoint from '../ResponsiveUtilities/desktop_breakpoint';
import TabletBreakpoint from '../ResponsiveUtilities/tablet_breakpoint';
import PhoneBreakpoint from '../ResponsiveUtilities/phone_breakpoint';
import colors from '../../styleguide/colors';
import logo from '../../assets/img/niceleads-logo.png';
import facebook from './ico-facebook.png';
import medium from './ico-medium.png';
import twitter from './ico-twitter.png';
import store1 from "../../assets/img/app-store.png";
import store2 from "../../assets/img/google-play.png"
const breakpoints = {
    desktop: '(min-width: 1025px)',
    tablet: '(max-width: 1024px)',
    plus: '(max-width: 800px)',
    phone: '(max-width: 767px)',
};


// TODO: once social pages are made, add links t0 anchor tags
function Footer() {
    const redirectToAppDownload = ele => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const deepLinkURL = "https://jlkds.app.link/";
        const androidAppURL = "https://play.google.com/store/apps/details?id=com.niceleads";
        const iosAppURL = "https://apps.apple.com/us/app/niceleads/id1561013535";
		if (isMobile) {
  			window.location.href = deepLinkURL;
		} else {
			if(ele == 2) // android: playstore redirect
                window.open( androidAppURL, "_blank"); 
            else if(ele == 1) // ios: appstore redirect
                window.open( iosAppURL, "_blank");
		}
    };

    return (
        <FooterWrapper>
            <DesktopBreakpoint>
                <Row>
                    <Col xs={12} sm={5} style={{ marginLeft: '1%', marginTop: '20px' }}>
                        <Image src={logo} alt="NiceLeads" />
                    </Col>
                    <Col xs={12} sm={5} style={{ marginLeft: '1%', marginTop: '20px'}}>
                        <div className="appLayout" style={{ float: 'right' }}>
                            <a onClick={() => { redirectToAppDownload(2) }}><img width={129} style={{ marginLeft: '20px'}} src={store2} alt="" /> </a>
                            <a onClick={() => { redirectToAppDownload(1) }}><img width={129} style={{ marginLeft: '20px'}} src={store1} alt="" /> </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={5} style={{ marginLeft: '1%' }} />
                    <Col xs={12} sm={5} style={{ marginLeft: '1%' }}>
                        <CopyRightRow style={{ float: 'right', marginRight: '0px' }} >
                            {/* <a href="/"><SocialLink src={facebook} alt="FaceBook" /></a>
                            <a href="/"><SocialLink src={medium} alt="Medium" /></a>
                            <a href="/"><SocialLink src={twitter} alt="Twitter" /></a> */}
                            <CopyRight  >&copy; 2022 NiceLeads, Inc. - All Rights Reserved.</CopyRight>
                        </CopyRightRow>
                    </Col>
                </Row>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <Row>
                    <Col xs={12} sm={5} style={{ marginLeft: '1%', marginTop: '20px' }}>
                        <Image src={logo} alt="NiceLeads" />
                    </Col>
                    <Col xs={12} sm={5} style={{ marginLeft: '1%', marginTop: '20px'}}>
                        <div className="appLayout" style={{ float: 'right' }}>
                            <a onClick={() => { redirectToAppDownload(2) }}><img width={129} style={{ marginLeft: '20px'}} src={store2} alt="" /> </a>
                            <a onClick={() => { redirectToAppDownload(1) }}><img width={129} style={{ marginLeft: '20px'}} src={store1} alt="" /> </a>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col />
                    <Col>
                        <CopyRightRow>
                            {/* <a href="/"><SocialLink src={facebook} alt="FaceBook" /></a>
                            <a href="/"><SocialLink src={medium} alt="Medium" /></a>
                            <a href="/"><SocialLink src={twitter} alt="Twitter" /></a> */}
                            <CopyRight>&copy; 2022 NiceLeads, Inc. - All Rights Reserved.</CopyRight>
                        </CopyRightRow>
                    </Col>
                </Row>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <PhoneFooter>
                    <Row style={{ marginTop: '20px' }}>
                        <Image src={logo} alt="NiceLeads" />
                        <div style={{ width: '100%', margin: '10px 0px' }} >
                            <a onClick={() => { redirectToAppDownload(2) }}><img width={129} style={{ marginLeft: '20px'}} src={store2} alt="" /> </a>
                            <a onClick={() => { redirectToAppDownload(1) }}><img width={129} style={{ marginLeft: '20px'}} src={store1} alt="" /> </a>
                        </div>
                    </Row>
                    <CopyRight>&copy; 2022 NiceLeads, Inc. - All Rights Reserved.</CopyRight>
                </PhoneFooter>
            </PhoneBreakpoint>

        </FooterWrapper>
    );
}

export const Image = styled.img`
  margin: auto;
  width: auto;
  @media ${breakpoints.phone} {
    width: 150px;
  }
`;

const FooterWrapper = styled.div`
  overflow: hidden;
  min-height: 120px;
  max-width: 100%;
  background-color: ${colors.softGray};
`;

const PhoneFooter = styled(Col)`

`;

const CopyRightRow = styled(Row)`

`;

const CopyRight = styled.div`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: -0.3px;
  color: ${colors.gray};
  margin-top: 4px;
  font-size: 15px;
  margin-left: 10px;
  @media ${breakpoints.tablet} {
      font-size: 12px;
  }
`;

const SocialLink = styled.img`
  padding: 5px;
  width: 24px;
  height: 24px;
`;

export default Footer;