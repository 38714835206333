import React from 'react';
import { Container, Row } from 'react-bootstrap';
import logo from "../../assets/img/niceleads-logo.png";
import phone from "../../assets/img/iphone-1.png";
import store1 from "../../assets/img/app-store.png";
import store2 from "../../assets/img/google-play.png"
import './AppDownload.css'
const AppDownload = () => {
    const redirectToAppDownload = ele => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const deepLinkURL = "https://jlkds.app.link/";
        const androidAppURL = "https://play.google.com/store/apps/details?id=com.niceleads";
        const iosAppURL = "https://apps.apple.com/us/app/niceleads/id1561013535";
		if (isMobile) {
  			window.location.href = deepLinkURL;
		} else {
			if(ele == 2) // android: playstore redirect
                window.open( androidAppURL, "_blank"); 
            else if(ele == 1) // ios: appstore redirect
                window.open( iosAppURL, "_blank");
		}
    };

    return (
        <section className="d-flex justify-content-center align-items-center mainSection">
            <Container className='box'>
                    <div className='mb-5'>
                        <nav className="navbar">
                            <a className="navbar-brand" href="#">
                                <img className="img-" src={logo} width="230px" alt="NiceLeads" />
                            </a>
                        </nav>
                    </div>

                <Row className="container" style={{margin: '10px'}}>
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div>
                            <h1 style={{color: '#5a9eec'}}>Download the app! </h1>
                            <h5>Network with your colleagues that invite you.</h5>
                            <br/>
                            <p style={{color: '#7c7c7c'}}>Register on your phone </p>
                            <div className="layout">
                                <a onClick={() => { redirectToAppDownload(2) }}><img src={store2} alt="" /> </a>
                                <a onClick={() => { redirectToAppDownload(1) }}><img src={store1} alt="" /> </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-lg-center imgPosition" >
                        <img src={phone} alt="" />
                    </div>
                </Row>
            </Container>
        </section>
    );
};

export default AppDownload;