import {
  Row,
  Col
} from 'react-bootstrap';
import styled from 'styled-components';
import breakpoint from "styled-components-breakpoint";
import colors from "../../styleguide/colors";

const breakpoints = {
  desktop: '(min-width: 1025px)',
  tablet: '(max-width: 1024px)',
  plus: '(max-width: 800px)',
  phone: '(max-width: 767px)',
};


export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Section = styled(Row)`
  max-width 90%;
  @media (max-width: 1255px) {
    margin-left: 15%;
    margin-right: 15%;
  }
  @media ${breakpoints.tablet} {
    margin-left: 15%;
    margin-right: 15%;
  }
`;

export const StyledCol = styled(Col)`
`;

export const ImageCol = styled(Col)`
  align-items: center;
  display: flex;
  justify-content: center;
  @media ${breakpoints.tablet} {
    justify-content: flex-start;
  }
  
`;

export const InnerRow = styled(Row)`
  @media (min-width: 500px) {
    margin-left: 15%
  }
`;

export const Image = styled.img`
  margin: auto;
  width: auto;
  @media ${breakpoints.tablet} {
    width: 400px;
  }
  @media ${breakpoints.phone} {
    width: 300px;
    height: 365px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-top: 30px;
  }
`;

export const StyledTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  line-height: 36px;
  color: ${colors.titleBlack};
  margin: auto;
  margin-top: 50px;
  margin-bottom: 10px;

  @media ${breakpoints.tablet} {
    margin-top: 30px;
    font-size: 20px;
    line-height: 25px;
  }
  @media ${breakpoints.phone} {
    font-size: 16px;
  }
}
`;

export const StyledRow = styled.div`
  text-align: left;
  font-size: 18px;
  color: ${colors.offBlack};
  margin-top: 35px;

  @media ${breakpoints.tablet} {
    font-size: 14px;
    margin-top: 20px;
  }
  @media ${breakpoints.phone} {
    font-size: 12px;
  }
}
`;
