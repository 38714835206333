import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import breakpoint from "styled-components-breakpoint";
import colors from "../../styleguide/colors";

const breakpoints = {
  desktop: '(min-width: 1025px)',
  tablet: '(max-width: 1024px)',
  plus: '(max-width: 800px)',
  phone: '(max-width: 767px)',
};

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Section = styled(Row)`
  max-width: 90%;
  @media ${breakpoints.tablet} {
    max-width: 65%;
  }
`;

export const StyledCol = styled(Col)`
@media (min-width: 500px) {
  margin-right: 10%
}
  
`;

export const ImageCol = styled(Col)`
  align-items: center;
  display: flex;
  justify-content: center;
  
`;

export const Header = styled(Col)`
  color: ${colors.offBlack}
`;

export const Image = styled.img`
  margin: auto;
  margin-top: 100px;
  width: 600px;
  @media (max-width: 1440px) {
    margin-top: 50px;
    width: 500px;
  }
  @media ${breakpoints.tablet} {
    margin-top: 50px;
    width: 500px;
  }
  @media ${breakpoints.tablet} {
    margin-top: 50px;
    width: 350px;
  }
`;

export const StyledTitle = styled.div`
  font-size: 30px;
  width 600px;
  font-weight: 700;
  line-height: 36px;
  color: ${colors.offBlack};
  margin: auto;
  margin-top: 25%;
  text-align: center;
  @media (max-width: 1440px) {
    font-size: 25px;
  }
  @media ${breakpoints.tablet} {
    margin-top: 80px;
    font-size: 22px;
  }

  @media ${breakpoints.tablet} {
    margin-top: 100px;
    font-size: 16px;
  }
}
`;

export const StyledBullet = styled.div`
  text-align: left;
  font-size: 18px;
  width: 600px;
  color: ${colors.bulletGray};
  margin: 0 auto;
  margin-top: 40px;

  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media ${breakpoints.tablet} {
    font-size: 16px;
  }
  @media ${breakpoints.phone} {
    width: 300px;
    font-size: 12px;
    margin-top: 25px;
  }

  :before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    background-color: ${colors.offBlack};

    margin-right: 10px;
    margin-left: -20px;
    border-radius: 7.5px;
    width: 10px;
    height: 10px;
    @media ${breakpoints.phone} {
      width: 8px;
      height: 8px;
    }
  }
}
`;

