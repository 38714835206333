import React from 'react';
import {
  Wrapper,
  HeroOverlay,
  Hero
} from './heroHeaderStyle';


const HeroHeader = ({ style, text, heroImage, row1, row2, subText, children, fluid = false }) => {
  return (
    <Wrapper
      style={style ? { ...style.wrapper, backgroundImage: `url(${heroImage})`, minHeight: "717px" } : { backgroundImage: `url(${heroImage})` }}>
      <HeroOverlay style={style ? style.overlay : null} fluid={fluid}>
        <h1 style={style ? style.header : null}>{text}</h1>
        {children}
        {row1 ? row1 : null}
        {row2 ? row2 : null}
        {subText ? subText : ''}
      </HeroOverlay>
      <Hero src={heroImage} />
    </Wrapper>
  );
};


export default HeroHeader;
