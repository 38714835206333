import React, { useState, useEffect, useRef } from 'react';
import {
    Wrapper,
    Header,
    HeaderButton,
    HeaderContainer,
    StyledSubText,
    BottomSubText,
    SubText,
    BottomSection,
    BottomSectionText,
    BlueLine
} from './homeStyle';
import HeroHeader from "../../components/HeroHeader/HeroHeader";
import MiddleSection from "../../components/MiddleSection/MiddleSection";
import PhoneSection from "../../components/PhoneSection/PhoneSection";
import EmailField from "../../components/EmailField/EmailField";
import heroImage from "../../assets/img/hero-image.png";
import iphone1 from '../../assets/img/iphone-1.png';
import iphone2 from '../../assets/img/iphone-2.png';
import iphone3 from '../../assets/img/iphone-3.png';
import iphone4 from '../../assets/img/iphone-4.png';
import mirrorEffect from '../../assets/img/mirror-effect.png';
import setFrequency from '../../assets/img/set-frequency.png';
import colors from '../../styleguide/colors';
import store1 from "../../assets/img/app-store.png";
import store2 from "../../assets/img/google-play.png"

function Home() {

    const [visibleSection, setVisibleSection] = useState();

    const headerRef = useRef(null);
    const wishListRef = useRef(null);
    const trustFilterRef = useRef(null);
    const matchRef = useRef(null);
    const privacyRef = useRef(null);
    const frequencyRef = useRef(null);

    const sectionRefs = [
        { section: "wish-list", ref: wishListRef },
        { section: "trust-filters", ref: trustFilterRef },
        { section: "match", ref: matchRef },
        { section: "privacy", ref: privacyRef },
        { section: "frequency", ref: frequencyRef },
    ]

    const scrollTo = ele => {
        ele.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };


    const getDimensions = ele => {
        const { height } = ele.getBoundingClientRect();
        const offsetTop = ele.offsetTop;
        const offsetBottom = offsetTop + height;

        return {
            height,
            offsetTop,
            offsetBottom,
        };
    };

    const redirectToAppDownload = ele => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const deepLinkURL = "https://jlkds.app.link/";
        const androidAppURL = "https://play.google.com/store/apps/details?id=com.niceleads";
        const iosAppURL = "https://apps.apple.com/us/app/niceleads/id1561013535";
		if (isMobile) {
  			window.location.href = deepLinkURL;
		} else {
			if(ele == 2) // android: playstore redirect
                window.open( androidAppURL, "_blank"); 
            else if(ele == 1) // ios: appstore redirect
                window.open( iosAppURL, "_blank");
		}
    };

    useEffect(() => {

        const handleScroll = () => {
            const { height: headerHeight } = getDimensions(headerRef.current);
            const scrollPosition = window.scrollY + headerHeight;
            const selected = sectionRefs.find(({ section, ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
            });

            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };

        handleScroll()
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [visibleSection]);

    return (
        <Wrapper fluid>
            <HeroHeader
                heroImage={heroImage}
                text={`Easier And More Efficient Business Networking`}
                fluid={true}
                /*row1={
                    <EmailField />
                }
                row2={
                    <StyledSubText>
                        <SubText><i>Launching soon</i></SubText>
                    </StyledSubText>
                }*/
            >
                <p className='headerText'>NiceLeads confidentially connects professionals who can help each other, based on criteria you each set.</p>
                <p className='headerAppDownText'>Available for Download Now</p>
                <div className="layout">
                    <a onClick={() => { redirectToAppDownload(2) }}><img src={store2} alt="" /> </a>
                    <a onClick={() => { redirectToAppDownload(1) }}><img src={store1} alt="" /> </a>
                </div>
            </HeroHeader>
            <BlueLine />
            <MiddleSection
                image={iphone1}
                title={'Take the “work” out of networking and meet the people you want to meet—today!'}
                rows={[
                    "We’ve all wasted a lot of time networking to get the right introduction. Out of that frustration, NiceLeads was born.",
                    "Who you meet tomorrow can literally change the path of your life, personally or professionally. That has always captured our attention.",
                    "The question is how can we focus on the people that are worth our time?  People we can trust, not “randos” who spam us with requests to connect.",
                    "NiceLeads takes the “work” out of networking by matching you with other professionals who can actually help you... based on criteria each of you set.  Now, you can immediately get to the right person at the company you want to meet. All it takes is a desire to help the other NiceLeads member who helps you, a quid pro quo, as they say.",
                    "Because in life, we help people who help us."
                ]}
            />
            <Header sticky="top" ref={headerRef}>
                <HeaderContainer>
                    <HeaderButton
                        variant="primary"
                        onClick={() => { scrollTo(wishListRef.current) }}
                        style={visibleSection === "wish-list" ? { borderColor: colors.softGray, fontWeight: '750' } : {}}
                    >
                        Create Your Wish List
                    </HeaderButton>
                    <HeaderButton
                        variant="primary"
                        onClick={() => { scrollTo(trustFilterRef.current) }}
                        style={visibleSection === "trust-filters" ? { borderColor: colors.softGray, fontWeight: '750' } : {}}
                    >
                        Networking Preferences
                    </HeaderButton>
                    <HeaderButton
                        variant="primary"
                        onClick={() => { scrollTo(matchRef.current) }}
                        style={visibleSection === "match" ? { borderColor: colors.softGray, fontWeight: '750' } : {}}
                    >
                        We Match You
                    </HeaderButton>
                    <HeaderButton
                        variant="primary"
                        onClick={() => { scrollTo(privacyRef.current) }}
                        style={visibleSection === "privacy" ? { borderColor: colors.softGray, fontWeight: '750' } : {}}
                    >
                        Your Privacy
                    </HeaderButton>
                    <HeaderButton
                        variant="primary"
                        onClick={() => { scrollTo(frequencyRef.current) }}
                        style={visibleSection === "frequency" ? { borderColor: colors.softGray, fontWeight: '750' } : {}}
                    >
                        Set Your Frequency
                    </HeaderButton>
                </HeaderContainer>
            </Header>
            <a id="wish-list" ref={wishListRef}>
                <PhoneSection

                    image={iphone2}
                    bgColor={colors.slide1}
                    title={'What Do You Want To Accomplish + Who Do You Want To Meet?'}
                    rows={[
                        "What’s your “ask?” Let others know what you want to accomplish when you get the introduction.",
                        "List the companies you want to get in to.",
                        "It is that simple to set up your Wish List."
                    ]}
                />
            </a>
            <a id="trust-filters" ref={trustFilterRef}>
                <PhoneSection
                    image={iphone3}
                    bgColor={colors.slide2}
                    title={'Networking Preferences Save You Time'}
                    rows={[
                        "NiceLeads matches two members based on preferences each set.",
                        "Don’t want to network with complete strangers? Select “Private Networking” where we only match you with other members when you both know people in common via the address book in your phone.",
                        "Or, anytime you want, revise these and select other filters like inside or outside your company, location, a group you’ve opted in to, or open networking. We’ll find the best match on the planet for you. ",
                        "You stay in total control so no one wastes time."
                    ]}
                />
            </a>
            <a id="match" ref={matchRef}>
                <PhoneSection
                    image={iphone4}
                    bgColor={colors.slide3}
                    title={'We Make The Matches'}
                    rows={[
                        "Immediately after setting up your Wish List, our algorithms match you with the most valuable contacts based on your networking preferences.",
                        "As more people join the platform, more matches come your way.",
                        "Less time spent prospecting means more time spent selling.",
                        "By the way, No one can simply reach out and try to sell you something.  Everyone we match you with can help you based on the criteria you each set."
                    ]}
                />
            </a>
            <a id="privacy" ref={privacyRef}>
                <PhoneSection
                    image={mirrorEffect}
                    addSpace={true}
                    bgColor={colors.slide4}
                    title={'We Respect Your Privacy'}
                    rows={[
                        "Like popular dating apps, you remain remain anonymous until you opt in to each request.",
                        "We never, ever share the data from your address book, even after you accept a request.",
                        "Once your request is accepted, you can reach out and chat with the other NiceLeads member. If it is declined, their ID remains completely anonymous."
                    ]}
                />
            </a>
            <a id="frequency" ref={frequencyRef}>
                <PhoneSection
                    image={setFrequency}
                    bgColor={colors.slide5}
                    title={'How Often Do You Want To Hear From Us?'}
                    rows={[
                        "Daily, weekly, monthly… never… it’s your choice.",
                        "Emails come with targeted recommendations of who we suggest you network with, again only based on criteria you each set."
                    ]}
                />
            </a>
           
        </Wrapper >
    );
}

export default Home;