import React from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import {
  Title,
  Paragraph
} from './style';
import blog1 from './blog1.png';

class Blog1 extends React.Component {

  render() {
    return (
      <div>
        <div style={{ width: '100%', height: 10, backgroundColor: 'rgba(216,216,216,0.42)' }}></div>
        <Container className="container" style={{ paddingTop: 30, paddingBottom: 30, maxWidth: 'none' }}>
          <Row style={{ textAlign: 'center' }}>
            <Col xs={12}>
              <Title style={{ marginBottom: 35, marginTop: 45 }}>
                The NiceLeads Story
              </Title>
              <Title style={{ marginBottom: 35, marginTop: 45 }}>
                By Lee Blaylock, CEO
              </Title>
            </Col>
          </Row>
          <Row style={{ textAlign: 'center' }}>
            <Col xs={1} md={2} />
            <Col xs={10} md={8}>
              <Image fluid src={blog1} />
              <Paragraph>
                My story is the NiceLeads story, and it starts with this fact about me:
              </Paragraph>
              <Paragraph style={{ fontStyle: 'italic' }}>
                I’ve always been fascinated by how who you meet can change the vector of your life.
              </Paragraph>
              <Paragraph>
                At work, in your personal life, everywhere. Maybe not immediately, maybe a few years down the road.
              </Paragraph>
              <Paragraph>
                When my father died, I was 22. There’s never a good time to lose a parent, but the earlier it is, the more you miss. I needed someone who could help me just as my career was beginning.
              </Paragraph>
              <Paragraph>
                The first person I turned to was Vin Prothro, the father of a good friend who’d known me since third grade. He’d started two semiconductor companies and had been a venture capitalist. When I wrote him a letter (this was before the internet), he agreed to be my mentor. We both knew I could never repay him monetarily, but he always said “help others when you’re in a position to be helpful.”
              </Paragraph>
              <Paragraph>
                I’ve done my best to honor Vin by honoring that advice.
              </Paragraph>
              <Paragraph>
                Vin also taught me that if you reach out to experienced people, they’ll often respond, especially if they’re interested in learning from or helping others.
              </Paragraph>
              <Paragraph>
                It happened again a few years later when I wrote to the late Michael Jordan. Not the basketball player but the then-CEO of Westinghouse, who turned it into CBS, an amazing board room accomplishment. I was working for Dell Computer at the time and he was on the Dell board. He had a house in Dallas not far from me, so I left a handwritten letter on his doorstep. Two weeks later, his assistant called and said Mr. Jordan would be happy to see me. In New York City! I flew up and we met in his home. Over the next year we met 7 or 8 times. He offered to invest in my business without me asking and, among other things, he taught me how to run a board meeting, an invaluable lesson.
              </Paragraph>
              <Paragraph>
                I’d started at Dell—badge #244—when I was still in college. I really enjoyed my time there, lived overseas for them, and had a long run. But I wanted to get into software as that’s where the real value is. And that’s when Vin Prothro came back into the picture, offering me a job running a VAR channel for the iButton they were launching.
              </Paragraph>
              <Paragraph>
                When I started my first company, called ServiceLane, it was funded by Austin Ventures, Fremont Ventures, and Silicon Valley Bank, all of whom I needed introductions to. (Ironically, my board member from Austin Ventures became the Vice President of Marketing at LinkedIn, the company we’re most likely to be compared to. We’ve studied LinkedIn closely: Our business model and value-add to both professionals and enterprise sales teams is very different!)
              </Paragraph>
              <Paragraph>
                I created my second company, a private client investment advisor called Annandale Capital, with two friends in the investment industry: One was Vin’s son-in-law.
              </Paragraph>
              <Paragraph>
                Starting my own companies relied on two things: getting introductions to customers and getting introductions to investors. Try convincing $100mm clients to let you manage their personal money without introductions, trust, and relationships. It was while running Annandale that the seed of what would become NiceLeads was planted in my head. It was this realization:
              </Paragraph>
              <Paragraph style={{ fontStyle: 'italic' }}>
                We barter who we know in order to get to somebody we want to meet, BUT only if we trust the person we’re introducing and believe the intro will be beneficial to all parties.
              </Paragraph>
              <Paragraph>
                Sound obvious? It’s not, at least not to most people. I realized as I took dozens of meetings, had endless cups of coffee, and kissed a lot of frogs that my getting to someone I really wanted to see usually meant helping someone else. And our mutual currency is our contacts.
              </Paragraph>
              <Paragraph>
                Sure, I’m willing to help you with an introduction that’s important to you. But I’m more motivated if you’re willing to help me. Helping can mean different things: an introduction, an investment tip, lending a hand tosomeone else less fortunate, setting up a golf game...
              </Paragraph>
              <Paragraph>
                Quid pro quo can take many forms. The point is, it has to work both ways, because in business we will help people who help us.
              </Paragraph>
              <Paragraph>
                NiceLeads is a platform optimized for enterprise networking. We want to build the most efficient service for putting the right person in front of you, or your sales team, at the right time. Someone who can help you all achieve your professional goals, whatever it is. BUT with one caveat: You have to be willing to help them in return.
              </Paragraph>
              <Paragraph>
                I’ve worked for Dell Technologies, Dallas Semiconductor, and Oracle. I started ServiceLane and Annandale Capital. I’m honored that colleagues from all 5 companies have invested with me and invested in NiceLeads.
              </Paragraph>
              <Paragraph>
                Business is all about relationships: That’s my philosophy, that’s my story, and that’s why NiceLeads exists.
              </Paragraph>
            </Col>
            <Col xs={1} md={2} />
          </Row>
          <Row>
          </Row>
        </Container>
      </div>
    );
  }
};

export default Blog1;
