import React from 'react';
import MediaQuery from 'react-responsive';

const breakpoints = {
    desktop: '(min-width: 1256px)',
    tablet: '(min-width: 768px) and (max-width: 1255px)',
    phone: '(max-width: 767px)',
};

export default function Breakpoint(props) {
    const breakpoint = breakpoints[props.name] || breakpoints.desktop;
    return (
        <MediaQuery {...props} query={breakpoint} >
            { props.children}
        </MediaQuery >
    );
}
