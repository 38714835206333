import React from 'react';
import {
    Wrapper,
    Image,
    StyledTitle,
    StyledRow,
    InnerRow,
    StyledCol,
    ImageCol,
    Section
} from './middleSectionStyle';
import DesktopBreakpoint from '../ResponsiveUtilities/desktop_breakpoint';
import TabletBreakpoint from '../ResponsiveUtilities/tablet_breakpoint';
import PhoneBreakpoint from '../ResponsiveUtilities/phone_breakpoint';
import colors from "../../styleguide/colors";


const MiddleSection = ({ style, image, title, rows }) => {
    return (
        <Wrapper style={{ ...style, backgroundColor: `${colors.tan}` }}>
            <DesktopBreakpoint>
                <Section>
                    <StyledCol>
                        <InnerRow>
                            <StyledTitle>{title}</StyledTitle>
                        </InnerRow>
                        <InnerRow>
                            {rows.map(row => {
                                return (
                                    <StyledRow>{row}</StyledRow>
                                )
                            }
                            )}
                        </InnerRow>
                    </StyledCol>
                    <ImageCol>
                        <Image style={{ maxWidth: '80%' }} src={image} />
                    </ImageCol>
                </Section>
            </DesktopBreakpoint>
            <TabletBreakpoint>
                <Section>
                    <InnerRow>
                        <StyledTitle>{title}</StyledTitle>
                    </InnerRow>
                    <InnerRow>
                        {rows.map(row => {
                            return (
                                <StyledRow>{row}</StyledRow>
                            )
                        }
                        )}
                    </InnerRow>
                    <Image src={image} />
                </Section>
            </TabletBreakpoint>
            <PhoneBreakpoint>
                <Section>
                    <InnerRow>
                        <StyledTitle>{title}</StyledTitle>
                    </InnerRow>
                    <InnerRow>
                        {rows.map(row => {
                            return (
                                <StyledRow>{row}</StyledRow>
                            )
                        }
                        )}
                    </InnerRow>
                    <Image src={image} />
                </Section>
            </PhoneBreakpoint>

        </Wrapper>
    );
};

export default MiddleSection;
