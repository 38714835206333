export default {
  black: '#000000',
  offBlack: '#333333',
  titleBlack: '#3a3a3a',
  white: '#ffffff',
  blueBlue: '#3050d5',
  azul: '#365aef',
  charcoal: '#30302e',
  flatGreen: '#649a4b',
  turtleGreen: '#84bc55',
  softGray: 'rgba(216, 216, 216, 0.29)',
  gray: '#4a4a4a',
  bulletGray: '#6b6b6b',
  yellow: '#ffb400',
  transparent: 'rgba(0,0,0,0.4)',
  nlBlue: '#1a59a1',
  tan: '#eef1eb',
  slide1: '#dbeefb',
  slide2: '#dbf5fb',
  slide3: '#e9fbdb',
  slide4: '#dbfbf0',
  slide5: '#fcf5e2',
  darkGray: '#484c52'
};
